.pre-sale-timer {
    text-align: center;
    max-width: 420px;
    width: 100%;
    float: right;
    padding: 30px;
    padding-top:0px;
    padding-bottom:0px;
    background: -moz-linear-gradient(bottom, rgba(251, 189, 24, 1) 0%, rgba(251, 189, 24, 0) 100%);
    background: -webkit-linear-gradient(bottom, rgba(251, 189, 24, 1) 0%, rgba(251, 189, 24, 0) 100%);
    background: linear-gradient(to top, rgba(251, 189, 24, 1) 0%, rgba(251, 189, 24, 0) 100%);
    bottom:0px;
    position: relative;
    height: 100vh;
    display: table;
}
.pre-sale-timer > div{
    display: table-cell;
    vertical-align: middle;
}
.pre-sale-timer h3 {
    font-size: 32px;
    margin-bottom: 25px;
}
.pre-sale-timer h3 span {
    font-weight: 300;
}
.clock ul {
    list-style: none;
    margin: 0 -14px;
    padding: 0;
}
.clock li {
    width: 25%;
    padding: 0 14px;
    float: left;
    position: relative;
}
.clock li span {
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: block;
    padding: 12px 2px;
    font-size: 36px;
    font-weight: 700;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    line-height: 36px;
}
.clock li span em {
    display: block;
    font-family: 'PT Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}
.clock li + li:before,
.clock li + li:after {
    background: #fbbd18;
    position: absolute;
    top: 50%;
    left: -3px;
    content: "";
    -webkit-border-radius: 50%;
    border-radius: 50%;
    width: 6px;
    height: 6px;
}
.clock li + li:before {
    margin-top: -12px;
}
.clock li + li:after {
    margin-top: 6px;
}